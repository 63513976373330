/**
 * Creates a debounced version of a function that will only execute after a specified delay has passed since the last time it was invoked.
 * If immediate is set to true, the function will be executed immediately the first time it is invoked.
 *
 * @param fn - The function to be debounced.
 * @param [wait=250] - The amount of time in milliseconds to wait before executing the debounced function.
 * @param [immediate=false] - If true, the function will be executed immediately the first time it is invoked.
 * @returns - The debounced function.
 */
export default function useDebounce(fn: VoidFunction, wait = 250, immediate = false) {
  let timeout: NodeJS.Timeout | undefined

  function debounced(...args: unknown[]) {

    const later = () => {
      timeout = undefined
      if (immediate !== true) {
        fn.apply(this, args)
      }
    }

    clearTimeout(timeout)

    if (immediate === true && timeout === undefined) {
      fn.apply(this, args)
    }
    timeout = setTimeout(later, wait)
  }

  debounced.cancel = () => {
    clearTimeout(timeout)
  }

  return debounced
}
